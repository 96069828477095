import React, { useEffect, useState } from 'react'
import TwoColumn from '../../../components/two-column'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import {Helmet} from 'react-helmet'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '../../../components/tabs';
import NumberFormat from 'react-number-format';
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import BigNumber from '../../../helpers/bignumber.mjs'

const MrrCalculator = (props) => {  
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(0)
  const [range, setRange] = useState(0)
  
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
  }
  
  useEffect (() => {
    setRange(3)
    setItemsPerPage(9)
  }, [])

  const solidFoundations = {
    title: "Monthly Recurring Revenue calculator",
    description: [
      {
        text: "Our free monthly recurring revenue calculator helps you measure the growth of your SaaS business. You can even compare different churn rates to see how effective churn management will improve your business and use our calculator to predict your businesses future revenue."
      }
    ],
    sectionDevelopers: true,
    image: require('../../../images/tools-mrr-calculator.svg'),
    svg: true,
    isBanner: true,
    imageName: 'MRR Banner',
    backgroundColor: '#F3F5FB',
    textColor: '#252422',
    imagePosition: 'right',
  }

  const [mrrValues, setmrrValues] = useState({
    currentMrr: 10000,
    revenueGrowth: 5000,
    revenueChurn1: 6.0,
    revenueChurn2: 8.0,
    revenueChurn3: 10.0,
    mrrMonths: 6
  })

  const churnTabs = [ 
    {
      churn: mrrValues.revenueChurn1,
      tableValues: []
    },
    {
      churn: mrrValues.revenueChurn2,
      tableValues: []
    },
    {
      churn: mrrValues.revenueChurn3,
      tableValues: []
    }
  ]

  const dataTable = []

  for(var a=0; a < mrrValues.mrrMonths; a++) {
    dataTable.push({
      date: 0,
      churn1: 0,
      churn2: 0,
      churn3: 0
    })
  }

  const createTableValues = (index) => {
    const monthsArr = [
      "Jan", 
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]

    BigNumber.set({ DECIMAL_PLACES: 2 })

    var initialMRR = new BigNumber(mrrValues.currentMrr),
        rGrowth = mrrValues.revenueGrowth,
        churnNew = churnTabs[index].churn/100,
        d = new Date(),
        n = d.getMonth() + 1,
        month = monthsArr[n],
        year = d.getFullYear()
        
    for(var a=0; a < mrrValues.mrrMonths; a++) {
      var lostMrr = initialMRR.times(churnNew),
          monthEndMrr = initialMRR.minus(lostMrr).plus(rGrowth)

      var dateEntry = monthsArr[n] + " " + year

      console.log(monthEndMrr.toFixed(5))

      churnTabs[index].tableValues.push(
        <div className="tr" key={a}>
          <div className="td">{dateEntry}</div>
          <div className="td">
            ${abbreviateNumber(initialMRR.toFixed(5), 2)}
          </div>
          <div className="td">
            ${abbreviateNumber(mrrValues.revenueGrowth, 2)}
          </div>
          <div className="td">
            <span>-</span>
            ${abbreviateNumber(lostMrr, 2)}
          </div>
          <div className="td">
            ${abbreviateNumber(monthEndMrr.toFixed(5), 2)}
          </div>
        </div>
      )

      dataTable[a].date = dateEntry
      
      if(index === 0) {
        dataTable[a].churn1 = initialMRR.toFixed(2) 
      } else if(index === 1) {
        dataTable[a].churn2 = initialMRR.toFixed(2) 
      } else {
        dataTable[a].churn3 = initialMRR.toFixed(2) 
      }

      initialMRR = monthEndMrr

      if(n <= 10) {
        n++
      } else {
        n = 0
        year++
      }
    }

    console.log("Data Table length:" + dataTable.length)

    return churnTabs[index].tableValues
  }

  function abbreviateNumber(num, digits) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

  const yTickFormatter = (tickItem) => {
    return abbreviateNumber(tickItem)
  } 

  return (
    <Layout className="monthly-recurring-revenue-calculator">
      <SEO 
        title="Free Monthly Recurring Revenue (MRR) Calculator | Billsby" 
        description="Calculate the impact of changes to MRR on your subscription business, forecast MRR growth over five years and compare different churn scenarios." 
        url="https://www.billsby.com/resources/tools/monthly-recurring-revenue-calculator"
      />
      
      <Helmet>
        <script src="https://peterolson.github.io/BigInteger.js/BigInteger.min.js"></script>
      </Helmet>

      <TwoColumn content={solidFoundations} />
      <div className="section-mrr-form">
        <div className="container">
          <div className="input-row">
            <p className="label-mrr">Current MRR</p>
            <NumberFormat id="currentMrr" className={`custom-input`} thousandSeparator={true} prefix={'$'} value={mrrValues.currentMrr} isNumericString={true} maxLength="17" decimalScale={0} onValueChange={(values, event) => {
              const {value} = values

              setmrrValues({
                ...mrrValues,
                currentMrr: value
              })
            }} />
          </div>
          <div className="input-row">
            <p className="label-mrr">Revenue Growth</p>
            <NumberFormat id="revenueGrowth" className={`custom-input`} thousandSeparator={true} prefix={'$'} value={mrrValues.revenueGrowth} maxLength="17" decimalScale={0} onValueChange={(values, event) => {
              const {value} = values

              setmrrValues({
                ...mrrValues,
                revenueGrowth: value
              })
            }} />
          </div>
          <div className="input-row churn-row">
            <p className="label-mrr">Revenue Churn</p>
            <div className="input-group">
              <NumberFormat id="revenueChurn1" className={`custom-input input-sm`} thousandSeparator={true} decimalSeparator={'.'} suffix={'%'} value={mrrValues.revenueChurn1} decimalScale={1} fixedDecimalScale={true} maxLength="19" onValueChange={(values, event) => {
              const {value} = values

              setmrrValues({
                ...mrrValues,
                revenueChurn1: value
              })
            }} />
              <NumberFormat id="revenueChurn2" className={`custom-input input-sm`} thousandSeparator={true} decimalSeparator={'.'} suffix={'%'} value={mrrValues.revenueChurn2} decimalScale={1} fixedDecimalScale={true} maxLength="19" onValueChange={(values, event) => {
              const {value} = values

              setmrrValues({
                ...mrrValues,
                revenueChurn2: value
              })
            }} />
              <NumberFormat id="revenueChurn3" className={`custom-input input-sm`} thousandSeparator={true} decimalSeparator={'.'} suffix={'%'} value={mrrValues.revenueChurn3} decimalScale={1} fixedDecimalScale={true} maxLength="19" onValueChange={(values, event) => {
                const {value} = values
  
                setmrrValues({
                  ...mrrValues,
                  revenueChurn3: value
                })
              }} />
            </div>
          </div>
          <div className="input-row">
            <p className="label-mrr">Project Over</p>
            <div className="input-group">
              <NumberFormat id="mrrMonths" className={`custom-input input-sm`} thousandSeparator={true} value={mrrValues.mrrMonths} maxLength="2" decimalScale={0} onValueChange={(values, event) => {
                const {value} = values
  
                setmrrValues({
                  ...mrrValues,
                  mrrMonths: value
                })
              }} />
              <span>months</span>
            </div>
          </div>
        </div>
      </div>

      <div className="section-chart">
        <div className="container">
          <div className="chart-wrapper">
            <ResponsiveContainer width="100%" height={540} >
              <LineChart data={dataTable}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="date" axisLine={false} tickLine={false} tickMargin={17} />
                <YAxis axisLine={false} tickLine={false} tickMargin={7} stroke="#8c9aab" tickFormatter={yTickFormatter} tickCount={9} />
                <Tooltip 
                  active={false} 
                  contentStyle={{ padding: '20px 20px 14px' }} 
                  labelStyle={{ marginBottom: '13px', fontFamily: 'Roboto', fontWeight: 700, fontSize: '17px', color: '#252422' }} 
                  cursor={{ stroke: '#252422', strokeWidth: 2 }} 
                  formatter={(value =>  <NumberFormat thousandSeparator={true} prefix={'$'} value={value} isNumericString={true} displayType="text" decimalScale={0} />)}
                />
                <Legend />
                <Line type="linear" dataKey="churn1" stroke="#d22630" strokeWidth={2} />
                <Line type="linear" dataKey="churn2" stroke="#25405d" strokeWidth={2} />
                <Line type="linear" dataKey="churn3" stroke="#fe7f2d" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div >

      <div className="section-table">
        <div className="container">
          <Tabs initialValue={0}>
            <TabList>
              {
                churnTabs.map((item, i) => (
                  <Tab name={i} key={i}>
                    <span className="churn">
                      {item.churn}%
                    </span>
                  </Tab>
                ))
              }
            </TabList>

            {churnTabs.map((items, i) => (
              <TabPanel name={i} key={i}>
                <div className="table-wrapper">
                  <div className="tr">
                    <div className="th">Month</div>
                    <div className="th">Initial MRR</div>
                    <div className="th">New MRR</div>
                    <div className="th">Lost MRR</div>
                    <div className="th">Month End MRR</div>
                  </div>
                  {
                    createTableValues(i).slice(
                      activePage * itemsPerPage - itemsPerPage,
                      activePage * itemsPerPage
                    )
                  }
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} className="icon icon-grid" />}
                    lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} className="icon icon-grid" />}
                    prevPageText={<FontAwesomeIcon icon={faChevronLeft} className="icon icon-grid" />}
                    nextPageText={<FontAwesomeIcon icon={faChevronRight} className="icon icon-grid" />}
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={mrrValues.mrrMonths}
                    pageRangeDisplayed={range}
                    onChange={handlePageChange}
                  />
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </Layout>
  )
}

export default MrrCalculator